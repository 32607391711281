.navLinkActive img {
  filter: invert(24%) sepia(48%) saturate(243%) hue-rotate(142deg)
    brightness(89%) contrast(95%);
}
.navLinkInactive img {
  filter: invert(100%) sepia(100%) saturate(15%) hue-rotate(238deg)
    brightness(105%) contrast(100%);
}
.img-color {
  filter: invert(24%) sepia(48%) saturate(243%) hue-rotate(142deg)
    brightness(89%) contrast(95%);
}
/* Title attribute on hover */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.1s;
  visibility: visible;
  border-radius: 7px;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #2e4347;
  color: white;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -0.3em;
  left: 160%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

/* LOADER CONTAINER */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LOADER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #2e4347;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* LOADER SMALL */

.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 100%;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #2e4347;
  border-color: #2e4347 transparent #2e4347 transparent;
  animation: lds-dual-ring 0.4s linear infinite;
}

/* LOADER SMALL YELLOW */
.lds-dual-ring-light-green {
  display: inline-block;
  width: 30px;
  height: 100%;
}
.lds-dual-ring-light-green:after {
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #b2bfc1;
  border-color: #b2bfc1 transparent #b2bfc1 transparent;
  animation: lds-dual-ring 0.4s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tables: Selector classes, Do not remove empty classes */
.cell-class {
}

.table-checkbox-th {
}

input.table-checkbox-th[type='checkbox']:checked {
  background-color: white;
  accent-color: black;
}

input.table-checkbox-th[type='checkbox']:checked::before {
  content: '-';
  color: black;
}

/* input.checkbox {
  border: 1px solid #2e4347;
  background-color: white;
  padding: 1px;
}

input.checkbox[type="checkbox"]:checked {
  background-color: #2e4347;
  background-image: none;
} */

input.checkbox[type='checkbox'] {
  width: 20px;
  height: 20px;
  border: 1px solid #2e4347;
  background-color: white;
  position: relative;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: text-bottom;
}
input.checkbox[type='checkbox']:checked:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  background: #2e4347;
  width: 14px;
  height: 14px;
  border-radius: 5px;
  background-image: none;
}
input.checkbox[type='checkbox']:hover,
input.checkbox[type='checkbox']:focus {
  border: 1px solid #2e4347;
  background: white;
}

input.checkbox[type='checkbox']:checked {
  background: white;
}

/* Filters */
.button-icon-reverse img {
  filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(213deg)
    brightness(100%) contrast(99%);
}
.button-icon-reverse:hover img {
  filter: invert(19%) sepia(17%) saturate(910%) hue-rotate(142deg)
    brightness(97%) contrast(83%);
}
.button-icon img {
  filter: invert(19%) sepia(17%) saturate(910%) hue-rotate(142deg)
    brightness(97%) contrast(83%);
}
.button-icon:hover img {
  filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(213deg)
    brightness(100%) contrast(99%);
}
.button-icon-nohover img {
  filter: invert(19%) sepia(17%) saturate(910%) hue-rotate(142deg)
    brightness(97%) contrast(83%);
}

/* Dropwdown */

.dropdown-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  background: #ffffff;
}

.dropdown-container select::-ms-expand {
  display: none;
}

.dropdown-container select > option {
  background-color: white;
}

/* Scrollbar geocoding datalist */

.geocoding-datalist {
  scrollbar-width: thin;
  scrollbar-color: #91898c #666263;
}

.geocoding-datalist::-webkit-scrollbar {
  width: 8px;
}

.geocoding-datalist::-webkit-scrollbar-track {
  background: #ccc8c9;
}

.geocoding-datalist::-webkit-scrollbar-thumb {
  background-color: #91898c;
  border-radius: 10px;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 0.6rem !important;
  border: transparent !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: transparent !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #2e4347 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #b2bfc1 !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #6d7f83 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #313131 !important;
}

/* Input time */

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
input[type='time'] {
  padding: 0;
}

/* Box shadow */
.box-shadow {
  -webkit-box-shadow: 0px 5px 10px -1px rgba(153, 147, 153, 0.74);
  -moz-box-shadow: 0px 5px 10px -1px rgba(153, 147, 153, 0.74);
  box-shadow: 0px 5px 10px -1px rgba(153, 147, 153, 0.74);
}

/* toogle */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ebf0f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: #d3d3d3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ebf0f1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d3d3d3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: #2e4347;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Hide Input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.hide-scroll {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customScroll::-webkit-scrollbar {
  width: 6px;
}

.customScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #B2BFC1;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #2e4347;
}

.customScroll::-webkit-scrollbar-button {
  display: none;
}
