@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
  html {
    font-family: 'Inter', sans-serif;
    color: 'red';
  }
  input[type="password"]::placeholder {
    font-weight: normal !important;
  }
  input[type="text"]:focus{
    --tw-ring-color: transparent !important;
  }
}

